import React from 'react';
import { connect } from 'react-redux';
import { deleteAccount } from '../../../../actions/account';
import SidebarOffBudgetAccountsContainer from './sidebar-off-budget-accounts-container';

const SidebarOffBudgetAccountsConnector = ({
  accounts,
  accountsById,
  budgetDetails,
  deleteAccount,
  settings,
}) => {
  return (
    <SidebarOffBudgetAccountsContainer
      accounts={accounts}
      accountsById={accountsById}
      budgetDetails={budgetDetails}
      deleteAccount={deleteAccount}
      settings={settings}
    />
  );
};

const mapStateToProps = ({
  accounts,
  accountsById,
  budgetDetails,
  settings,
}) => ({
  accounts,
  accountsById,
  budgetDetails,
  settings,
});

export default connect(
  mapStateToProps,
  { deleteAccount }
)(SidebarOffBudgetAccountsConnector);
