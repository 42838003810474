import { createSelector } from 'reselect';
import moment from 'moment';
import {
  sum,
  sumTimeframeSubcategories,
} from '../utilities/budgeting-formulas';

const getCurrentTimeframe = (state, { monthToDisplay }) => monthToDisplay;

const getAllTimeframes = ({ timeframes }) => timeframes;

const getAllTimeframeSubcategoriesByCategoryGroup = (
  { budget },
  { categoryGroupId }
) => {
  if (budget.timeframe_subcategories && budget.timeframe_subcategories.length) {
    let categoryIdsInCategoryGroup = budget.subcategories
      .filter((subcat) => {
        return subcat.section_id === categoryGroupId;
      })
      .map((s) => s.id);

    return budget.timeframe_subcategories.filter((timeframe_subcategory) => {
      return categoryIdsInCategoryGroup.includes(
        timeframe_subcategory.category_id
      );
    });
  }

  return [];
};

const getTimeframeSubcategoriesByMonth = (
  { budget },
  { categoryGroupId, monthToDisplay }
) => {
  if (
    budget.timeframe_subcategories &&
    budget.timeframe_subcategories.length > 0
  ) {
    let categoryIdsInCategoryGroup = budget.subcategories
      .filter((subcat) => {
        return subcat.section_id === categoryGroupId;
      })
      .map((s) => s.id);

    return budget.timeframe_subcategories.filter((timeframe_subcategory) => {
      return (
        categoryIdsInCategoryGroup.includes(
          timeframe_subcategory.category_id
        ) && timeframe_subcategory.timeframe === monthToDisplay
      );
    });
  }

  return [];
};

export const getCategoryGroupTotalBudgetedByMonthFactory = () => {
  return createSelector(
    getTimeframeSubcategoriesByMonth,
    (timeframeSubcategoriesByMonth) => {
      return sum(timeframeSubcategoriesByMonth, 'budgeted');
    }
  );
};

export const getCategoryGroupTotalActivityByMonthFactory = () => {
  return createSelector(
    getTimeframeSubcategoriesByMonth,
    (timeframeSubcategoriesByMonth) => {
      return sum(timeframeSubcategoriesByMonth, 'spent');
    }
  );
};

export const getCategoryGroupTotalRemainingByMonthFactory = () => {
  return createSelector(
    [
      getCurrentTimeframe,
      getAllTimeframeSubcategoriesByCategoryGroup,
      getAllTimeframes,
    ],
    (currentTimeframe, timeframeSubcategoriesByCategoryGroup, timeframes) => {
      return sumTimeframeSubcategories(
        currentTimeframe,
        timeframes,
        timeframeSubcategoriesByCategoryGroup,
        (subCat, month) => {
          return (
            subCat.timeframe === month.format('MMYYYY') &&
            moment(subCat.timeframe, 'MMYYYY').isSameOrBefore(
              moment(currentTimeframe, 'MMYYYY')
            )
          );
        }
      );
    }
  );
};
